import { SearchOptions } from 'api/types';
import { createUrl } from 'api/utils';
import { request } from '../http';
import {
  GET_HIGHLIGHT_VIDEO_CLIPS_URL_PATTERN,
  GET_FEED_FOLLOW_URL_PATTERN,
  GET_BEST_PODCASTS_URL_PATTERN,
  GET_PODCAST_DETAIL_URL_PATTERN,
  GET_PODCAST_EPISODE_DETAIL_URL_PATTERN,
  GET_PODCAST_FEED_DETAIL_URL_PATTERN,
  SEARCH_PODCAST_URL_PATTERN,
  SEARCH_EPISODE_URL_PATTERN,
  GET_PODCAST_FEED_EPISODE_DETAIL_URL_PATTERN,
  GET_PODCAST_EPISODES_URL_PATTERN,
  GET_HOMEPAGE_HIGHLIGHT_VIDEO_CLIPS_URL_PATTERN,
  LISTENING_QUEUE_URL_PATTERN,
} from './constants';

import {
  GetHighlightVideoClipsOptions,
  HighlightVideoClipsResponse,
  FeedFollowArgs,
  GetFeedFollowResponse,
  GetBestRankedPoscastsResponse,
  GetPodcastDetailsResponse,
  GetPodcastEpisodeDetailResponse,
  PodcastSearchResponse,
  EpisodeSearchResponse,
  GetPodcastDetailsOptions,
  GetPodcastEpisodeDetailOptions,
  GetPodcastEpisodesOptions,
  GetPodcastEpisodesResponse,
  ListeningQueueResponse,
  ListeningQueueArgs,
} from './types';

export async function getHighlightVideoClips(
  feedId: number,
  options?: GetHighlightVideoClipsOptions,
): Promise<HighlightVideoClipsResponse> {
  const { data } = await request.get(
    createUrl(GET_HIGHLIGHT_VIDEO_CLIPS_URL_PATTERN, {
      pathReplacements: { feedId },
      queryParams: options,
    }),
  );

  return data;
}

export async function getHomePageHighlightVideoClips(
  options?: GetHighlightVideoClipsOptions,
): Promise<HighlightVideoClipsResponse> {
  const { data } = await request.get(
    createUrl(GET_HOMEPAGE_HIGHLIGHT_VIDEO_CLIPS_URL_PATTERN, {
      queryParams: options,
    }),
  );
  return data;
}

export async function getFeedFollow(
  feedId: number,
): Promise<GetFeedFollowResponse> {
  const { data } = await request.get<GetFeedFollowResponse>(
    createUrl(GET_FEED_FOLLOW_URL_PATTERN, {
      pathReplacements: { feedId },
    }),
  );

  return data;
}

export async function addFeedFollow(args: FeedFollowArgs): Promise<void> {
  await request.post<void>(
    createUrl(GET_FEED_FOLLOW_URL_PATTERN, {
      pathReplacements: { feedId: args.feedId },
    }),
    {},
  );
}

export async function removeFeedFollow(args: FeedFollowArgs): Promise<void> {
  await request.delete<void>(
    createUrl(GET_FEED_FOLLOW_URL_PATTERN, {
      pathReplacements: { feedId: args.feedId },
    }),
  );
}

export async function getBestRankedPoscasts(
  page?: number,
): Promise<GetBestRankedPoscastsResponse> {
  const { data } = await request.get<GetBestRankedPoscastsResponse>(
    GET_BEST_PODCASTS_URL_PATTERN,
    { params: { page } },
  );
  return data;
}

export async function getPodcastDetails(
  podcastId: string,
  includeEpisodeDetail?: boolean,
  includeEpisodeDescription?: boolean,
  opts?: GetPodcastDetailsOptions,
): Promise<GetPodcastDetailsResponse> {
  const url = createUrl(GET_PODCAST_DETAIL_URL_PATTERN, {
    pathReplacements: { podcastId },
    queryParams: opts,
  });

  const { data } = await request.get<GetPodcastDetailsResponse>(url, {
    params: { includeEpisodeDescription, includeEpisodeDetail },
  });
  return data;
}

export async function getPodcastDetailsFromFeed(
  podcastFeedId: number,
  includeEpisodeDetail?: boolean,
): Promise<GetPodcastDetailsResponse> {
  const url = createUrl(GET_PODCAST_FEED_DETAIL_URL_PATTERN, {
    pathReplacements: { podcastFeedId },
  });
  const { data } = await request.get<GetPodcastDetailsResponse>(url, {
    params: { includeEpisodeDetail },
  });
  return data;
}

export async function getPodcastEpisodeDetails(
  episodeId: string,
  podcastId: string,
  opts?: GetPodcastEpisodeDetailOptions,
): Promise<GetPodcastEpisodeDetailResponse> {
  const url = createUrl(GET_PODCAST_EPISODE_DETAIL_URL_PATTERN, {
    pathReplacements: {
      podcastId,
    },
    queryParams: {
      remoteEpisodeId: episodeId,
      includeExcerpts: false,
      includeTags: false,
      ...opts,
    },
  });
  const { data } = await request.get<GetPodcastEpisodeDetailResponse>(url);
  return data;
}

export async function getPodcastEpisodeDetailsFromFeed(
  episodeId: number,
  opts: GetPodcastEpisodeDetailOptions,
): Promise<GetPodcastEpisodeDetailResponse> {
  const url = createUrl(GET_PODCAST_FEED_EPISODE_DETAIL_URL_PATTERN, {
    pathReplacements: { episodeId },
    queryParams: opts,
  });
  const { data } = await request.get<GetPodcastEpisodeDetailResponse>(url);
  return data;
}

export async function getPodcastEpisodes(
  podcastId: string | number,
  opts?: GetPodcastEpisodesOptions,
): Promise<GetPodcastEpisodesResponse> {
  const url = createUrl(GET_PODCAST_EPISODES_URL_PATTERN, {
    pathReplacements: { podcastId },
    queryParams: opts,
  });

  const { data } = await request.get<GetPodcastEpisodesResponse>(url);

  return data;
}

export async function searchPodcasts(
  query: string,
  opts?: SearchOptions,
): Promise<PodcastSearchResponse> {
  const url = createUrl(SEARCH_PODCAST_URL_PATTERN, {
    queryParams: { ...opts, q: query },
  });
  const { data } = await request.get<PodcastSearchResponse>(url);
  return data;
}

export async function searchEpisodes(
  query: string,
  opts?: SearchOptions,
): Promise<EpisodeSearchResponse> {
  const url = createUrl(SEARCH_EPISODE_URL_PATTERN, {
    queryParams: { ...opts, q: query },
  });
  const { data } = await request.get<EpisodeSearchResponse>(url);
  return data;
}

export async function getListeningQueue(args: ListeningQueueArgs) {
  const { data } = await request.get<ListeningQueueResponse>(
    createUrl(LISTENING_QUEUE_URL_PATTERN, {
      queryParams: args,
    }),
  );

  return data;
}
